import React from 'react'

interface MenuIconFromPathProps {
    path:string
    alt:string
}
const MenuIconFromPath:React.VFC<MenuIconFromPathProps> = ({path,alt}) => {
  return <img src={path} alt={alt}/>
}

export default MenuIconFromPath