import {createAction, createAsyncAction} from 'typesafe-actions';
import {ConfigData} from '../../types/ConfigData';
import {Country} from '../../types/Country';
import {SessionData} from '../../types/Session';
import {Subdivision} from '../../types/Subdivision';
import {LocaleLanguages, TimeZone} from '../../types/TimeZone';
import {GetSubdivisionsListRequestPayload} from './payloads';
import {CustomerInfo, CustomerOfficeType, GlobalCurrencyInfo} from '../../types/CustomerInfo';

export const countriesList = createAsyncAction(
    'GET_COUNTRIES_LIST',
    'GET_COUNTRIES_LIST_SUCCESSED',
    'GET_COUNTRIES_LIST_FAILED',
)<undefined, Country[], Error>();

export const timeZonesList = createAsyncAction(
    'GET_TIMEZONES_LIST',
    'GET_TIMEZONES_LIST_SUCCESSED',
    'GET_TIMEZONES_LIST_FAILED',
)<undefined, TimeZone[], Error>();

export const getSubdivisionData = createAsyncAction(
    'GET_SUBDIVISION_DATA',
    'GET_SUBDIVISION_DATA_SUCCESSED',
    'GET_SUBDIVISION_DATA_FAILED',
)<GetSubdivisionsListRequestPayload, Subdivision[], Error>();

export const getSessionData = createAsyncAction(
    'GET_SESSION_DATA',
    'GET_SESSION_DATA_SUCCESSED',
    'GET_SESSION_DATA_FAILED',
)<undefined, SessionData & { reload?: boolean }, Error>();

export const getConfigData = createAsyncAction(
    'GET_CONFIG_DATA',
    'GET_CONFIG_DATA_SUCCESSED',
    'GET_CONFIG_DATA_FAILED',
)<undefined, ConfigData, Error>();

export const getGlobalCurrencyData = createAsyncAction(
    'GET_GLOBAL_CURRENCY_DATA',
    'GET_GLOBAL_CURRENCY_DATA_SUCCESS',
    'GET_GLOBAL_CURRENCY_DATA_FAILED',
)<undefined, GlobalCurrencyInfo, Error>();

export const localLanguagesList = createAsyncAction(
    'GET_LOCALE_LANGUAGES_LIST',
    'GET_LOCALE_LANGUAGES_LIST_SUCCESS',
    'GET_LOCALE_LANGUAGES_LIST_FAILED',
)<undefined, LocaleLanguages[], undefined>();

export const getGlobalCustomerInfo = createAsyncAction(
    'GET_GLOBAL_CUSTOMER_INFO',
    'GET_GLOBAL_CUSTOMER_INFO_SUCCESS',
    'GET_GLOBAL_CUSTOMER_INFO_FAILED',
)<undefined, CustomerInfo, undefined>();

export const getCustomerSubdivisionsList = createAsyncAction(
    'GET_CUSTOMER_SUBDIVISIONS_LIST',
    'GET_CUSTOMER_SUBDIVISIONS_LIST_SUCCESSED',
    'GET_CUSTOMER_SUBDIVISIONS_LIST_FAILED',
)<undefined, CustomerInfo[], undefined>();

export const cleanAsyncReq = createAsyncAction(
    'CLEAN_ASYNC_REQ',
    'CLEAN_ASYNC_REQ_SUCCESS',
    'CLEAN_ASYNC_REQ_FAILED'
)<undefined, undefined, undefined>()

export const officeTypeUpdated = createAction(
    'UPDATE_CUSTOMER_OFFICE_TYPE'
)<CustomerOfficeType>();

export const addAsyncReqToClean = createAction('ADD_ASYNC_REQ_TO_CLEAN')<{ name: string, triggerAt: string }>()