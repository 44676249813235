import React, {useEffect, useMemo} from "react";
import {ReactComponent as TranscriptionIcon} from '../../assets/transcription.svg';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import CustomizedIconButton from "../IconButton/IconButton";
import {actions} from "../../store";
import {ReduxState} from "../../store/types";
import {showToast} from "../../utils/showToast";
import {useRawPermissions} from "../../hooks/usePermissions";
import {getPermission} from "../../utils/permissions/getPermission";
import {PermissionType} from "../../store/types/Permission";
import {FormatType} from "../../store/reducers/calls/reducer";

export type TranscriptionProps = {
    callRecordingId?: string;
    refresh: boolean;
    downloadPermission?: string[];
};

const Transcription: React.VFC<TranscriptionProps> = ({
    callRecordingId,
    refresh,
    downloadPermission
}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation()

    const isTranscriptionAvailable = useSelector<ReduxState, boolean>(
        (state) => state.calls.isTranscriptionAvailable,
    );

    const isTranscriptionDownloading = useSelector<ReduxState, boolean>(
        (state) => state.calls.isTranscriptionDownloading,
    );

    const permissions = useRawPermissions();
    const transcriptionPermission = useMemo(() => {
        if(!downloadPermission) return PermissionType.Visible;
        return getPermission(permissions, ...downloadPermission) as PermissionType;
    }, [permissions, downloadPermission]);

    const isDisabled = useMemo(() => {
        return !isTranscriptionAvailable || isTranscriptionDownloading || transcriptionPermission == PermissionType.ReadOnly
    }, [isTranscriptionAvailable, isTranscriptionDownloading, transcriptionPermission]);

    const isHidden = useMemo(() => {
        return transcriptionPermission != PermissionType.Visible
    }, [transcriptionPermission]);

    const handleDownloadFile = () => {
        if(callRecordingId)
        {
            showToast(t<string>('common:downloadWillStartShortly'));
            dispatch(actions.downloadTranscriptionFile.request({
                callRecordingId: callRecordingId,
                type: FormatType.JSON
            }))
        }
    }

    useEffect(() => {
        if (refresh || callRecordingId) {
            callRecordingId && dispatch(actions.checkIfTranscriptionExist.request({callRecordingId: callRecordingId}))
        }
    }, [refresh, callRecordingId]);

    if(isHidden) {
        return (<></>);
    }

    return (
        <>
            <CustomizedIconButton
                onClick={handleDownloadFile}
                tooltipText={isDisabled && !isTranscriptionDownloading ? t('tooltips:calls.disabledDownloadTranscription') : t('tooltips:calls.downloadTranscription')}
                disabled={isDisabled}
                dataTestId={'button-download-transcription'}
                skipPermission
                above
            >
                <TranscriptionIcon/>

            </CustomizedIconButton>
        </>

    )
}

export default Transcription