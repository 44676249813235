import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import List from '@material-ui/core/List';
import {useTranslation} from 'react-i18next';
import history from '../../history';
import {ReactComponent as Logout} from '../../assets/logout-right.svg';
import SidebarSection from './SidebarSection';
import {useSidebarContext,} from '../../hooks/useSidebarContext';
import {cleanStringsForLabelAndLink, sections, sectionsIcons, useStyles} from './Sidebar.utils';
import LogoutMenuItem from '../LogoutMenuItem/LogoutMenuItem';
import Logo from '../Logo/Logo';
import Config from '../../config.json';
import CustomSidebarItem from './CustomSidebarItem';
import PermissionProvider from '../PermissionProvider/PermissionProvider';
import {ReduxState} from '../../store/types';
import {CustomerInfo, CustomerOfficeType, IndividualType} from '../../store/types/CustomerInfo';
import {actions} from '../../store';
import classNames from 'classnames';
import CustomizedTooltip from '../Tooltip/Tooltip';
import {CustomLinkKey, Permission, PermissionType, PortalPermission} from '../../store/types/Permission';
import camelCase from "camelcase";
import {ComponentPlugin} from "../../store/types/Plugins";
import MenuIconFromPath from "../MenuIconFromPath/MenuIconFromPath";
import {ReactComponent as DefaultIcon} from '../../assets/iframeicon.svg';


const Sidebar = () => {
    const dispatch = useDispatch();
    const {groupsOpenStatuses, changeGroupStatus, userType} = useSidebarContext();

    const classes = useStyles();
    const {t} = useTranslation();

    const [showShadow, setShowShadow] = useState(false);
    const scrollBoxRef = useRef<HTMLInputElement | null>(null);

    const i_office_type = useSelector<ReduxState, CustomerOfficeType | undefined>(
        (state) => state.generic?.customerOfficeType
    );
    const customer_info = useSelector<ReduxState, CustomerInfo | undefined>(
        (state) => state.generic?.globalCustomerInfo
    );
    const customerSubdivisionsList = useSelector<ReduxState, CustomerInfo[] | undefined>(
        (state) => state.generic?.customerSubdivisionsList
    );
    const i_customer = useSelector<ReduxState, number | undefined>(
        (state) => state.generic?.sessionData?.i_customer
    );
    const permissions = useSelector<ReduxState, PortalPermission | undefined>(
        (state) => state.permissions?.permissions,
    );

    const customLinks = useMemo(() => {
        let links = Config.CUSTOM_LINKS.split(';');
        const customLinksArray: { label: string; url: string }[] = [];

        if (links.length % 2 === 1) {
            links = links.slice(0, links.length - 1);
        }

        for (let i = 0; i < links.length; i += 2) {

            const data = cleanStringsForLabelAndLink(links[i], links[i + 1], i_customer?.toString() || undefined);

            customLinksArray.push({
                label: data.label,
                url: data.link,
            });
        }

        return customLinksArray;
    }, []);

    useEffect(() => {
        if (i_office_type === CustomerOfficeType.Hq) {
            if (customerSubdivisionsList === undefined) {
                dispatch(actions.getCustomerSubdivisionsList.request());
            } else if (customerSubdivisionsList !== null && customerSubdivisionsList.length === 0) {
                dispatch(actions.officeTypeUpdated(CustomerOfficeType.NoOffices));
            }
        }
    }, [i_office_type, customerSubdivisionsList]);

    useEffect(() => {
        handleScroll();
        window.addEventListener('resize', handleScroll);

        return () => {
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (scrollBoxRef.current) {
            const value =
                scrollBoxRef.current.clientHeight +
                scrollBoxRef.current.scrollTop <
                scrollBoxRef.current.scrollHeight;

            if (value !== showShadow) {
                setShowShadow(value);
            }
        }
    };

    let maxTextWidths = 0

    const customSections = {...sections}
    const customSectionIcons = {...sectionsIcons}

    if (localStorage.getItem('custom_plugins')) {
        const customPluginsConfig: ComponentPlugin[] = JSON.parse(localStorage.getItem('custom_plugins') ?? "[]")
        const unnamedTranslation = t('common:unnamed')

        // @ts-ignore
        customPluginsConfig.forEach((el: ComponentPlugin) => {

            if (!customSections.hasOwnProperty(camelCase(el.section || unnamedTranslation))
            ) {

                customSections[camelCase(el.section || el.linkLabel)] = {
                    sections: [{
                        route: el.route,
                        permission: [],
                        defaultName: el.linkLabel
                    }],
                    customSectionName: el.section || el.linkLabel,
                    permission: [],
                    segmentedUserAccountTypes: undefined
                }

                    customSectionIcons[camelCase(el.section || el.linkLabel)] = el.sectionLogo ? (
                        <MenuIconFromPath
                            path={`/plugins/${el.directory}/${el.sectionLogo}`}
                            alt={el.section || 'icon'}/>
                    ) : <DefaultIcon/>


            } else if (customSections[camelCase(el.section || unnamedTranslation)]?.sections.findIndex(e => el.route === e.route) === -1) {
                customSections[camelCase(el.section || unnamedTranslation)]?.sections.push({
                    route: el.route,
                    permission: [],
                    defaultName: el.linkLabel
                })
            }

        })
    }

    Object.keys(customSections).forEach((v) => {
        customSections[v].sections.forEach(el => {
            if (maxTextWidths < t(`sidebar.${el.route}`, el.defaultName).length) {
                maxTextWidths = t(`sidebar.${el.route}`, el.defaultName).length
            }
        })

    });

    return (
        <div>
            <div
                className={classes.sidebar}
                style={{minWidth: 220 + maxTextWidths}}
                data-qa="sidebar-container"
                data-testid="sidebar-container"
            >
                <div className={classes.logoContainer}>
                    <div className={classes.logo}>
                        <Logo toWhite/>
                    </div>
                </div>

                <List
                    component="nav"
                    data-qa="admin-dashboard"
                    style={{flex: 1, overflowY: 'auto', overflowX: 'hidden'}}
                    onScroll={handleScroll}
                    ref={scrollBoxRef}
                >
                    {Object.keys(customSections).map((v, index) => (
                        <PermissionProvider
                            key={index}
                            permission={customSections[v].permission}
                            segmentedUserAccountRestricted={
                                customSections[v]?.segmentedUserAccountTypes?.length ?
                                    // @ts-ignore
                                    !customSections[v]?.segmentedUserAccountTypes?.includes(userType || IndividualType.Manager) : false
                            }
                        >
                            <SidebarSection
                                title={t(`sidebar.${v}`, customSections[v].customSectionName || v)}
                                items={customSections[v].sections.map((w) => ({
                                    title: t(`sidebar.${w.route}`, w.defaultName || t('common:unnamed')),
                                    route: w.route,
                                    permission: w.permission,
                                    segmentedUserAccountRestricted: w?.segmentedUserAccountTypes?.length ?
                                        // @ts-ignore
                                        !w?.segmentedUserAccountTypes.includes(userType || IndividualType.Manager) : false
                                }))}
                                isSidebarOpen
                                currentPath={history.location.pathname}
                                active={customSections[v].sections
                                    .map((v) => v.route)
                                    .includes(history.location.pathname)}
                                sectionOpened={groupsOpenStatuses[index]}
                                toggleSection={() => changeGroupStatus?.(index)}
                                sectionIcon={customSectionIcons[v]}
                                index={index}
                            />
                        </PermissionProvider>
                    ))}

                    <PermissionProvider permission={Permission.CustomLink.value}>
                        {customLinks.map((v, index) => (
                            <CustomSidebarItem
                                key={`custom-item-${index}`}
                                title={v.label}
                                url={v.url}
                                forcePermission={permissions && permissions?.["components"]?.[CustomLinkKey]?.["components"]?.[v.label]?.['permission'] != undefined ?
                                    permissions && permissions?.["components"]?.[CustomLinkKey]?.["components"]?.[v.label]?.['permission'] :
                                    permissions && permissions?.["components"]?.[CustomLinkKey]?.['permission'] != undefined ?
                                        // @ts-ignore
                                        permissions?.["components"]?.[CustomLinkKey]['permission'] : Config.DEFAULT_PERMISSION == "true" || Config.DEFAULT_PERMISSION ?
                                            PermissionType.Visible : PermissionType.Hidden}
                            />
                        ))}
                    </PermissionProvider>

                </List>
                <LogoutMenuItem
                    sectionIcon={<Logout/>}
                    onLogoutIconClick={() => {
                        dispatch(actions.removeAuthDataAndReload({reload: true, waitTime: 1000}));
                    }}
                    showShadow={showShadow}
                />
            </div>

            <div className={classes.hqIndicatorContainer}>
                <CustomizedTooltip
                    copy={false}
                    title={
                        <div className={classes.tooltipText}>
                            <div>{customer_info?.customer_info?.name || ''}</div>
                            <div>{customer_info?.customer_info?.companyname || ''}</div>
                        </div>
                    }
                    data-qa={`hq-indicator-tooltip`}
                    above={true}
                >
                    <div className={classNames(classes.hqIndicator,
                        i_office_type === CustomerOfficeType.Hq && customerSubdivisionsList !== undefined && classes.hq,
                        i_office_type === CustomerOfficeType.Hq && customerSubdivisionsList === undefined && classes.hidden,
                        i_office_type === CustomerOfficeType.Branch && classes.branch,
                        (!i_office_type || i_office_type === CustomerOfficeType.NoOffices) && classes.hidden
                    )}
                         data-testid={'hq-branch-indicator'}>
                        {i_office_type === CustomerOfficeType.Hq ? t(`sidebar.hq`) : t(`sidebar.branch`)}
                    </div>
                </CustomizedTooltip>
            </div>
        </div>
    );
};

export default Sidebar;
