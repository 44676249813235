import React, {useCallback, useMemo, useState} from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../../styles/Colors';
import {Column} from 'react-table';
import DataItemActions from '../../DataTable/DataItemActions';
import DataGrid from '../../DataGrid/DataGrid';
import CustomizedButton from '../../Button/Button';
import {AutoAttendantMenu} from '../../../store/types/AutoAttendant';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import {Add} from '@material-ui/icons';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import history from '../../../history';
import {actions} from '../../../store';
import AlertDialog from '../../AlertDialog/AlertDialog';
import {DialogButton} from '../../AlertDialog/DialogContainer';
import CreatMenuDialog from '../../AutoAttendants/CreateNewMenu/CreatMenuDialog';
import {getRoute, Routes} from '../../../routes/routes';
import PermissionProvider from '../../PermissionProvider/PermissionProvider';
import {Permission} from '../../../store/types/Permission';
import {translateAlways} from "../../../views/AutoAttendants/MenuCell";
import PermissionLinkCell from "../../DataTable/PermissionLinkCell";
import usePageTitle from "../../../hooks/usePageTitle";

const useStyles = makeStyles(() => ({
    button: {
        marginBottom: 32,
        '& .MuiButton-label': {
            paddingLeft: '0 !important',
            paddingRight: '5px !important',
        },
    },
    container: {
        maxWidth: 1040,
        padding: '22px 16px',
        '& .MuiTableRow-root': {
            '& .MuiTableCell-root:nth-child(1)': {
                paddingLeft: 64,
            },
        },

        '& tr th.MuiTableCell-root.MuiTableCell-head:nth-child(3)': {
            paddingLeft: 35,
        },

        '& .MuiTableBody-root .MuiTableRow-root:last-of-type': {
            borderBottom: 'unset',
        },
    },
    addNewMenuContainer: {
        width: 'fit-content'
    },
    tooltipName: {
        opacity: 0.5,
        fontSize: 12,
        paddingBottom: 5,
    },
    tooltipContainer: {
        display: 'block',
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    nameText: {
        fontSize: 16,
        color: Colors.Secondary1,
        cursor: 'pointer',
    },
    activeText: {
        color: Colors.Gray5,
    },
    removeModalContainer: {
        '& .MuiDialogContent-root': {
            minWidth: 280,
            width: 280,
            backgroundColor: Colors.White,
        },
    },
}));

const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    autoAttendantId: string | number,
    openRemoveMenu: (menuToRemove: AutoAttendantMenu) => void,
): Column<AutoAttendantMenu>[] => {
    return [
        {
            Header: t<string>('common:name'),
            accessor: 'name',
            Cell: function Cell(params) {
                const permission = Permission.CloudPBX.AutoAttendants.AutoAttendantDetails.Menu.MenuDetails.value;
                return (
                    <PermissionLinkCell
                        text={params.row.original.name}
                        onClick={() =>
                            history.push(
                                getRoute(Routes.AutoAttendantsMenu, {
                                    autoAttendantId: autoAttendantId,
                                    menuId: params.row.original.i_menu,
                                }),
                            )}
                        permissions={permission}
                    />
                );
            },
            width: 1,
        },
        {
            Header: t<string>('common:active'),
            accessor: 'period_desc',
            Cell: function Cell(params) {
                params.row.original.period_desc = params.row.original.period_desc.replace(
                    'or',
                    ' ',
                );

                const value = params.row.original.period_desc
                    .split(/(?=or)/g)
                    .map((v) => {
                        let value = v
                            .replace(/(\r\n|\n|\r)/gm, '')
                            .replace(/or /gm, '');

                        if (value.length > 100) {
                            value = value.substring(0, 100) + '...';
                        }

                        return value;
                    });

                return (
                    <OverflowTooltip
                        tooltip={
                            <span className={classes.tooltipContainer}>
                                <span className={classes.tooltipName}>
                                    {params.row.original.name}
                                </span>
                                {'\n'}
                                <span>
                                    {value.length < 4
                                        ? value.join('\n')
                                        : value.slice(0, 4).join('\n') +
                                        '\n...'}
                                </span>
                            </span>
                        }
                        text={translateAlways(params.row.original.period_desc, params.row.original.period)}
                        classes={{text: classes.activeText}}
                        copy={false}
                    />
                );
            },
            width: 3,
        },
        {
            Header: t<string>('common:actions'),
            accessor: 'i_menu',
            Cell: function Cell(params) {
                const isRoot = params.row.original.name === 'ROOT';
                return (
                    <DataItemActions
                        onDelete={
                            !isRoot && params.row.original.deleteEnabled
                                ? () => openRemoveMenu(params.row.original)
                                : undefined
                        }
                        onEdit={() =>
                            history.push(
                                getRoute(Routes.AutoAttendantsMenu, {
                                    autoAttendantId: autoAttendantId,
                                    menuId: params.row.original.i_menu,
                                }),
                            )
                        }
                        editPermission={
                            Permission.CloudPBX.AutoAttendants
                                .AutoAttendantDetails.Menu.MenuDetails.value
                        }
                        deletePermission={
                            Permission.CloudPBX.AutoAttendants
                                .AutoAttendantDetails.Menu.DeleteMenu.value
                        }
                    />
                );
            },
            width: 1,
            maxWidth: 150,
            minWidth: 150,
        },
    ];
};

type MenuFormProps = {
    autoAttendantId: number | string;
};

const MenuForm: React.VFC<MenuFormProps> = ({autoAttendantId}) => {
    usePageTitle();
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [menuToRemove, setMenuToRemove] = useState<AutoAttendantMenu | undefined>(undefined);
    const [isAddNewMenuDialogOpen, setIsAddNewMenuDialogOpen] = useState(false);

    const menus = useSelector<ReduxState, AutoAttendantMenu[]>(
        (v) => v.autoAttendants.autoAttendantMenus || [],
    );

    const autoAttendantsMenus = useMemo(
        () => menus.sort((a) => (a.name === 'ROOT' ? -1 : 1)),
        [menus],
    );

    const removeMenu = useCallback(() => {
        if (menuToRemove) {
            dispatch(
                actions.removeMenu.request({
                    i_menu: menuToRemove.i_menu,
                    autoAttendantId:
                        typeof autoAttendantId === 'string'
                            ? parseInt(autoAttendantId)
                            : autoAttendantId,
                    withRedirectToList: true,
                }),
            );
            setIsRemoveModalOpen(false);
        }
    }, [autoAttendantId, menuToRemove]);

    const openRemoveMenu = useCallback((menuToRemove: AutoAttendantMenu) => {
        setIsRemoveModalOpen(true);
        setMenuToRemove(menuToRemove);
    }, []);

    const columns = useMemo(
        () => generateColumns(t, classes, autoAttendantId, openRemoveMenu),
        [],
    );

    const handleAddMenuIsOpen = () =>
        setIsAddNewMenuDialogOpen(!isAddNewMenuDialogOpen);

    return (
        <div className={classes.container}>
            <PermissionProvider
                permission={
                    Permission.CloudPBX.AutoAttendants.AutoAttendantDetails.Menu
                        .AddNewMenu.value
                }
            >
                <div className={classes.addNewMenuContainer}>
                    <CustomizedButton
                        dataQa="add-new-menu-button"
                        primary
                        accent
                        className={classes.button}
                        onClick={handleAddMenuIsOpen}
                    >
                        <Add
                            htmlColor={Colors.White}
                            style={{height: 20, width: 20, marginRight: 5}}
                        />

                        {t('screens:autoAttendants.addAMenu')}
                    </CustomizedButton>
                </div>
            </PermissionProvider>

            <DataGrid
                columns={columns}
                data={autoAttendantsMenus}
                rowCount={autoAttendantsMenus.length}
                centeredRows
                hideFooter
                getItemId={(v) => v.i_menu}
            />

            <PermissionProvider
                permission={
                    Permission.CloudPBX.AutoAttendants.AutoAttendantDetails.Menu
                        .AddNewMenu.value
                }
            >
                <CreatMenuDialog
                    isOpen={isAddNewMenuDialogOpen}
                    toggleVisibility={handleAddMenuIsOpen}
                />
            </PermissionProvider>

            <AlertDialog
                isOpen={isRemoveModalOpen}
                hideHeader
                description={t('screens:autoAttendants.deleteMenuDescription', {
                    value: menuToRemove?.name,
                })}
                className={classes.removeModalContainer}
                contentClass="alert-content"
                dialogActionsButtons={[
                    <DialogButton
                        key="no"
                        label={t('common:cancel')}
                        onClick={() => {
                            setIsRemoveModalOpen(false);
                        }}
                    />,
                    <DialogButton
                        key="yes"
                        label={t('common:delete')}
                        className={classes.primaryModalButton}
                        onClick={removeMenu}
                    />,
                ]}
                dataQa="remove-menu-modal"
            />
        </div>
    );
};

export default MenuForm;
