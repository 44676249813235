import React from "react";
import {useTranslation} from "react-i18next";
import {CounterInterface} from "../../../store/types/CustomerAgreementCondition";
import ResourceMeter from "./ResourceMeter";

interface ResourceUseProps {
    product: CounterInterface;
}

const ResourceUse:React.VFC<ResourceUseProps> = ({product}) => {
    const {t} = useTranslation()

    return (
      <>
          <span>{t('common:serviceUsed')}</span>
          <ResourceMeter product={product} useStyling={false}/>
      </>
  )
}

export default ResourceUse