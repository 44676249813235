import MoreMenu from '../MoreMenu/MoreMenu';
import { MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../styles/Colors';
import { useDispatch } from 'react-redux';
import { actions } from '../../store';
import { showToast } from '../../utils/showToast';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';
import { FormatType } from '../../store/reducers/calls/reducer';
import AlertDialog from '../AlertDialog/AlertDialog';
import { DialogButton } from '../AlertDialog/DialogContainer';

const useStyles = makeStyles(() => ({
    callTranscriptionContainer: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '16px',
        fontSize: '12px',
        color: Colors.Text3,
        '& > div': {
            marginBottom: 0,
        },
    },
    alert:{
        '& .MuiDialogContent-root': {
            backgroundColor: Colors.White,
            minWidth:280,
            padding: 24,
        },
    }
}));

const TranscriptionAction: React.VFC<{
    callRecordingId: string;
}> = ({ callRecordingId }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [alertIsOpen, setAlertIsOpen] = useState(false);

    const permission = usePermissionContext();

    const readOnly = permission === PermissionType.ReadOnly;
    const hidden = permission === PermissionType.Hidden;

    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(
            actions.deleteTranscription.request({
                callRecordingId: callRecordingId,
                callback: () => {
                    callRecordingId &&
                        dispatch(
                            actions.checkIfTranscriptionExist.request({
                                callRecordingId: callRecordingId,
                            }),
                        );
                },
            }),
        );
    };

    const handleDeleteClick = () => setAlertIsOpen(true);

    const handleDownloadFile = (type: FormatType) => {
        if (callRecordingId) {
            showToast(t<string>('common:downloadWillStartShortly'));
            dispatch(
                actions.downloadTranscriptionFile.request({
                    callRecordingId: callRecordingId,
                    type,
                }),
            );
        }
    };

    return (
        <div className={classes.callTranscriptionContainer}>
            {t('screens:calls.callTranscription')}
            <MoreMenu hideMoreOptionsButton={false}>
                <MenuItem key="import-from-file" onClick={handleDeleteClick}>
                    {t('screens:calls.removeTranscription')}
                </MenuItem>

                {!hidden && (
                    <MenuItem
                        key="download-text-file"
                        disabled={readOnly}
                        onClick={() => handleDownloadFile(FormatType.TXT)}
                    >
                        {t('screens:calls.downloadTextFile')}
                    </MenuItem>
                )}
            </MoreMenu>

            <AlertDialog
                isOpen={alertIsOpen}
                className={classes.alert}
                dataQa={'delete-transcription'}
                description={t('screens:calls.removeTranscriptionAlert')}
                hideHeader={true}
                dataTestId={'release-dialog'}
                dialogActionsButtons={[
                    <DialogButton
                        key="no"
                        label={t('common:cancel')}
                        onClick={() => setAlertIsOpen(false)}
                    />,
                    <DialogButton
                        key="yes"
                        label={t('common:delete')}
                        onClick={handleDelete}
                    />,
                ]}
            />
        </div>
    );
};

export default TranscriptionAction;
