import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import {de, en, ptBR, th, uk} from '../translations';

import _, {merge} from 'lodash';

import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/pt-br';
import 'moment/locale/uk';
import 'moment/locale/th';

moment.locale(localStorage.getItem('user_language')?.toLowerCase() || 'en');

const translationsMap = {
    en,
    de,
    'pt-BR': ptBR,
    uk,
    th,
};

export const CUSTOM_TRANSLATION_KEY = 'custom_translation'

const loadCustomTranslations =  () => {
    try {
        // @ts-ignore
        const customTranslations = localStorage.getItem(CUSTOM_TRANSLATION_KEY) ? JSON.parse(localStorage.getItem(CUSTOM_TRANSLATION_KEY)) : {};

        const applyCustomTranslations = (namespace:any, translations:any, custom:any) => {
            if (custom && custom[namespace]) {
                return merge({}, translations, custom[namespace]);
            }

            return translations;
        };

        const supportedTranslations = Object.keys(translationsMap)
        const sections = Object.keys(en)
        const output = {}

        supportedTranslations.forEach(trans => {
            // @ts-ignore
            const translationFile = translationsMap[trans];
            // @ts-ignore
            output[trans] = {};

            sections.forEach(sec => {
                // @ts-ignore
                output[trans][sec] = applyCustomTranslations(sec, translationFile[sec], customTranslations[trans]);
            });
        });

        return  output

    } catch (error) {
        console.error('Use custom translation error:', error);
        return translationsMap;
    }
};

i18n.use(initReactI18next).init({
    ns: ['common', 'screens'],
    defaultNS: 'screens',
    resources:loadCustomTranslations(),
    lng: window.localStorage.getItem('user_language') || 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
        format: (value, format, lng) => {
            console.log(value,format,lng)
            if (!format) {
                return value;
            }

            return format.split(',').reduce((value: string, format: string) => {
                switch (format.trim()) {
                    case 'localeString':
                        return value.toLocaleString();
                    case 'enIndefiniteArticle':
                    case 'enIndefiniteArticleCapitalized': {
                        if (!lng || lng !== 'en') {
                            return value;
                        }

                        const letters = ['a', 'e', 'i', 'o', 'u', 'h'];
                        const firstLetter = value.substring(0, 1);
                        let article = '';
                        if (
                            letters.find(
                                (letter) =>
                                    firstLetter.toLowerCase() === letter,
                            )
                        ) {
                            article =
                                format === 'enIndefiniteArticleCapitalized'
                                    ? 'An'
                                    : 'an';
                        } else {
                            article =
                                format === 'enIndefiniteArticleCapitalized'
                                    ? 'A'
                                    : 'a';
                        }

                        return `${article} ${value}`;
                    }
                    case 'title': {
                        return value
                            .toLowerCase()
                            .split(' ')
                            .map((word: string) => _.upperFirst(word))
                            .join(' ');
                    }
                    case 'lowerCase': {
                        return value.toLowerCase();
                    }
                    default:
                        return value;
                }
            }, value);
        },
    },
});
export default i18n;
