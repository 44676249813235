import React from "react";
import CustomizedTooltip from "../../Tooltip/Tooltip";
import {ReactComponent as AudioCountIcon} from '../../../assets/audio_count.svg';
import classNames from "classnames";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Colors} from "../../../styles/Colors";


const useStyles = makeStyles(()=>({
    filesCounter: {
        width: 'fit-content',
        height: 16,
        background: Colors.Border,
        color: Colors.Secondary,
        borderRadius: 16,
        padding: '4px 12px 5px 12px',
        marginTop: 11,
        fontSize: 13,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',

        '& span': {
            marginLeft: '6px !important',
            marginTop: '0px !important'
        }
    }
}))

interface CallBadgeProps {
    callCount: number
}

const CallsBadge: React.VFC<CallBadgeProps> = ({callCount}) => {

    const classes = useStyles()
    const {t} = useTranslation()

    return (
        <CustomizedTooltip

            copy={false}
            interactive={false}
            title={t('tooltips:calls.audioCount', {value: callCount})}
            dataTestId={'audio-count-tooltip'}
            dataQa={'audio-count-tooltip'}

        >
            <div
                className={classNames(classes.filesCounter)}
                custom-attrib-file-counter="custom-attrib-file-counter"
                data-testid={'multi-files-badge'}>
                <AudioCountIcon/>
                <span>{callCount}</span>
            </div>

        </CustomizedTooltip>
    )
}


export default CallsBadge