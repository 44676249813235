import React, {useEffect, useMemo, useState} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import DialogContainer, {DialogButton} from '../AlertDialog/DialogContainer';
import {Colors} from '../../styles/Colors';
import CallRecordDots from './../Calls/CallRecordDots';
import {
    Call,
    GetHuntGroupListRequest,
    GetHuntGroupListResponse, SipCall,
    SipCallType
} from "../../store/actions/ringgroups/payloads";
import CustomizedRadio from "../Radio/Radio";
import {useFormik} from "formik";
import {ExtensionsListItem} from "../../store/reducers/extensions/extensions/reducer";
import {actions} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import AsyncSelectField from "../AsyncSelectField/AsyncSelectField";
import JSONFormData from "../../utils/JSONFormData";
import {AxiosResponse} from "axios";
import {api} from "../../store/services/axios";
import {Customer} from "../../services/endpoints";
import {ReduxState} from "../../store/types";
import {RingGroupType} from "../../store/types/RingGroup";
import {displayCallerText} from "../../views/CallHistory/ActiveCalls/ActiveCalls.utils";
import {ExtensionType} from "../../store/types/Extension";
import ExtensionWithCallsAsyncSelectFiled from "./ExtensionWithCallsAsyncSelectFiled";

interface TransferCallDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
    onSaveClick?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    caller: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callee: any;
    call: Call;
    type: SipCallType;
    i_customer: number | string,
    accounts: ExtensionType[],
    sipCalls?: SipCall[]

}

const useStyles = makeStyles(() => ({
    option: {
        display: 'flex',
        flexDirection: 'column',
        '& .label': {
            lineHeight: '48px',
            color: Colors.Black,
            fontSize: 16,
            weight: 400
        },

        '& .register, & .unregister': {
            fontSize: 14,
            weight: 400,
            lineHeight: '16px',
        },

        '& .register': {
            color: Colors.Support
        },
        '& .unregister': {
            color: Colors.Gray
        }
    },
    primaryActionButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 604,
            backgroundColor: Colors.SmokeBackground,
        },
    },
    inputsContainer: {
        padding: '0 12px 0 12px',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiInputBase-root': {
            background: Colors.SmokeBackground,
        },
    },
    inputRow: {
        display: 'flex',
        marginTop: 22,

        '& .MuiFormControl-root': {
            flex: 1,
            margin: '0 6px',
        },
    },
    iconMargin: {
        marginLeft: 10,
    },
    buttonsContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },


    container: {
        '& .MuiPaper-root': {
            width: 336,
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset !important',
            padding: '24px 24px 26px 24px !important',
            background: `${Colors.White} !important`,
        },
        '& .MuiDialogActions-root': {
            '& > :first-child': {
                marginRight: 20,
            },
        },
    },

    radioContainer: {
        marginRight: 32,
    },
}));

export interface TransferCallFormType {
    extTransferType: boolean,
    ringTransferType: boolean,
    callIdentifier: { id: string, tag?: string } | undefined
    extension?: ExtensionsListItem,
    ring?: RingGroupType
}


const TransferCallDialog: React.VFC<TransferCallDialogProps> = (
    {
        isOpen,
        toggleVisibility,
        caller,
        callee,
        type,
        i_customer,
        call,
        onSaveClick,
        accounts,
        sipCalls
    }) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const initState = {
        callIdentifier:undefined,
        extTransferType: true,
        ringTransferType: false,
        extension: undefined
    }

    const [init] = useState<TransferCallFormType>(initState)
    const {session_id, csrf_token} = useSelector((state: ReduxState) => state.auth);

    const {values, setFieldValue, submitForm,setValues} = useFormik<TransferCallFormType>({
        initialValues: {
            ...init,
            callIdentifier: {id: call.id, tag: call.tag}
        },
        onSubmit: (form) => {
            dispatch(actions.transferCall.request({
                ...form, callback: () => {
                    toggleVisibility?.();
                    onSaveClick?.();
                }
            }))
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        if(isOpen){
            setValues({...initState, callIdentifier: {id: call.id, tag: call.tag}})
        }

    }, [isOpen])

    const loadOptions2 = async (search: string, prevOptions: unknown[]) => {
        const limit = 30;

        const params: Partial<GetHuntGroupListRequest> = {
            name: search ? `%${search}%` : undefined,
            i_customer: i_customer,
            get_main_office_huntgroups: 1,
            limit: 30,
            offset: prevOptions.length
        };
        const body = new JSONFormData(session_id || '', csrf_token || '');
        body.setParams(params);

        const res: AxiosResponse<GetHuntGroupListResponse> = await api.post(
            Customer.GetHuntGroupList,
            body,
        );
        const items = res.data.huntgroup_list.map(item => ({
            ...item,
            label: item.name,
            value: item.id
        }));

        return {
            options: items,
            hasMore: res.data.total > prevOptions.length + limit,
        };
    };

    const handleChange = () => {
        setFieldValue('extTransferType', !values.extTransferType);
        setFieldValue('ringTransferType', !values.ringTransferType);
        setFieldValue('ring', undefined);
        setFieldValue('extension', undefined);
    }

    const ringSelectFiled = useMemo(() => (
        <AsyncSelectField
            title={t('screens:addToRingGroup.groupName')}
            loadOptions={loadOptions2}
            value={values.ring}
            onChange={(value) => {
                setFieldValue('ring', value);
            }}
            required
            filterOption={(option) => {
                return [caller.id, callee.id].find(item => item == option.value) == undefined;
            }}/>

    ), [values.ringTransferType, values.ring])

    const callerText = displayCallerText(accounts, caller);
    const calleeText = displayCallerText(accounts, callee);

    const leftText = callerText == 'call_supervision' || caller.id  == 'call_supervision' ? t('screens:calls.conference') : callerText;
    const rightText = calleeText == 'call_supervision' || callee.id  == 'call_supervision' ? t('screens:calls.conference') : calleeText;

    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="call-record-dialog"
            header={t('screens:callSettings.transferCall')}
            headerClass={classes.header}
            className={classes.modalContainer}
            dialogActionsButtons={[
                <DialogButton
                    className={classes.primaryActionButton}
                    key="close"
                    label={t('common:cancel')}
                    onClick={toggleVisibility}
                />,
                <DialogButton
                    className={classes.primaryActionButton}
                    key="save"
                    label={t('enums:inactiveMenuAction.Transfer')}
                    onClick={submitForm}
                    primary
                    disabled={!values.extension && !values.ring}
                />,
            ]}
        >


            <CallRecordDots
                leftText={leftText.length ? leftText : caller.id}
                rightText={rightText.length ? rightText : callee.id}
                type={type == SipCallType.Incoming ? 8 : 4}
                forwardLine={true}
            />

            <Grid className={classes.inputsContainer}>
                <Grid className={classes.inputRow}>
                    <CustomizedRadio
                        name={'extTransferType'}
                        value={values.extTransferType}
                        className={classes.radioContainer}
                        checked={
                            values.extTransferType
                        }
                        onChange={handleChange}
                        label={t('screens:dashboard.extension')}
                        dataQa={`call-ext-transfer-type`}
                    />

                    <CustomizedRadio
                        name={'ringTransferType'}
                        value={values.ringTransferType}
                        className={classes.radioContainer}
                        checked={
                            values.ringTransferType
                        }
                        label={t('screens:calls.ringGroup')}
                        dataQa={`call-ring-transfer-type`}
                        onChange={handleChange}

                    />
                </Grid>

            </Grid> <Grid className={classes.inputsContainer}>
            <Grid className={classes.inputRow}>
                {values.extTransferType ?
                    (
                        <ExtensionWithCallsAsyncSelectFiled
                            i_customer={i_customer}
                            value={values.extension}
                            setValue={(value) => setFieldValue('extension', value)}
                            ignoreIds={[caller.id, callee.id]}
                            disableClearable={true}
                            useExtensionFilter={true}
                            sipCalls={sipCalls}
                        />
                    ) :
                    ringSelectFiled
                }
            </Grid>

        </Grid>

        </DialogContainer>
    );
};

export default TransferCallDialog;
