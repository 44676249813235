import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DialogContainer, {DialogButton} from '../../AlertDialog/DialogContainer';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import {GlobalCurrencyInfo} from '../../../store/types/CustomerInfo';
import {generateTabs} from "../../../utils/generateTabs";
import {ReactComponent as DirectionRight} from '../../../assets/direction-right.svg';
import {useRawPermissions} from "../../../hooks/usePermissions";
import Tabs from "../../Tabs/Tabs";
import CallerInfo from "../CallerInfo";
import {CallRecordInformationTabsDialogProps, useStyles} from "./CallRecordInformationTabsDialog.utils";
import InfoTab from "./InfoTab";
import RecordingTab from "./RecordingTab";
import {actions} from "../../../store";
import {initialState, PlayingFile} from "../../../store/reducers/calls/reducer";

const CallRecordInformationTabsDialog: React.VFC<CallRecordInformationTabsDialogProps> = (
    {
        isOpen,
        toggleVisibility,
        callHistory,
        timezoneOffset,
        onDeleteClick,
        onDownloadClick,
        userDateTimeFormat,
        isDownloadingGoingOn,
        customerCurrency,
        downloadPermission,
        deletePermission,
        downloadTranscriptionPermission,
        filesQueue
    }) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();


    const currencyInfo = useSelector<
        ReduxState,
        GlobalCurrencyInfo | undefined
    >((state) => state.generic.globalCurrency);

    const onDownloadIconClick = () => {
        if (onDownloadClick)
            onDownloadClick(
                callHistory?.i_xdr || 0,
                callHistory?.cr_download_ids?.[0] || '0',
            );
    };


    const [tabIndex, setTabIndex] = useState(0);
    const permissions = useRawPermissions();


    const getCurrentFiles = (): PlayingFile[] => {
        const downloadIds = callHistory?.cr_download_ids || [];
        const fileId = callHistory?.h323_conf_id
        const i_xdr = callHistory?.i_xdr;
        let fileSet: PlayingFile[] = [];
        if (downloadIds.length && filesQueue) {
            for (const fq of filesQueue) {
                if (fq.length && fq[0].call_recording_id === downloadIds[0]
                    && fq[0].i_xdr === i_xdr) {
                    fileSet = fq.map(item => ({...item, h323_conf_id: fileId}));
                }
            }
        }

        return fileSet
    }

    useEffect(() => {
        isOpen && setTabIndex(0)

        const files = getCurrentFiles()

        isOpen && dispatch(actions.internalUpdateRecordingsPlayingState({
            ...initialState.internalRecordingsPlayer,
            currentFile: files.length ? files[0] : null,
            filesQueue: filesQueue?.filter(el => el.findIndex(internal => internal.i_xdr === callHistory?.i_xdr) !== -1) || []
        }));

        return () => {
            dispatch(actions.internalUpdateRecordingsPlayingState({
                ...initialState.recordingsPlayer
            }));
        }

    }, [isOpen]);

    const {tabNames, tabs} = useMemo(() => generateTabs(
        [
            {
                title: t('common:info'),
                permission: [],
                tab: (
                    <InfoTab customerCurrency={customerCurrency}
                             currencyInfo={currencyInfo}
                             record={callHistory}
                             userDateTimeFormat={userDateTimeFormat}
                             timezoneOffset={timezoneOffset}
                             callHistory={callHistory}
                    />
                ),
            },
            {
                title: t('screens:calls.recordingAndTranscriptionTabTitle'),
                permission: [],
                tab: (
                    <RecordingTab
                        record={callHistory}
                        downloadPermission={downloadPermission}
                        onDownloadIconClick={onDownloadIconClick}
                        isDownloadingGoingOn={isDownloadingGoingOn}
                        isOpen={isOpen && tabIndex == 1}
                        downloadTranscriptionPermission={downloadTranscriptionPermission}
                        deletePermission={deletePermission}
                        onDeleteClick={onDeleteClick}
                        filesQueue={filesQueue?.filter(el => el.findIndex(internal => internal.i_xdr === callHistory?.i_xdr) !== -1) || []}
                    />
                )
            }
        ],
        permissions
    ), [callHistory, tabIndex, isDownloadingGoingOn])

    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="call-record-dialog"
            header={t(`enums:callRecordTypeTitle.${callHistory?.bit_flags ? callHistory.bit_flags & 12 : undefined}`)}
            customHeaderWidget={(
                <>
                    <CallerInfo
                        caller={callHistory?.CLI || ''}
                        className={classes.noOverflow}
                        headerClassName={classes.noBold}
                    />
                    <DirectionRight/>
                    <CallerInfo
                        headerClassName={classes.noBold}
                        className={classes.noOverflow}
                        caller={callHistory?.CLD || ''}
                    />
                </>
            )}
            headerClass={classes.header}
            className={classes.modalContainer}
            dialogActionsButtons={[
                <DialogButton
                    className={classes.primaryActionButton}
                    key="close"
                    label={t('common:close')}
                    onClick={toggleVisibility}
                />,
            ]}
        >
            <Tabs
                tabsNames={tabNames}
                defaultTabIndex={tabIndex}
                onChange={(_, index) => {
                    setTabIndex(index)
                }}
                className={classes.tabContainer}
            >
                {tabs}
            </Tabs>
        </DialogContainer>
    );
};

export default CallRecordInformationTabsDialog;
