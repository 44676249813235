import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import Config from './config.json';
import {CUSTOM_TRANSLATION_KEY} from "./services/i18n";

const tagManagerArgs = {
    gtmId: Config.GTM_TAG_ID,
};

tagManagerArgs.gtmId.length > 0 && TagManager.initialize(tagManagerArgs);

const fetchCustomTranslations = async () => {

    try {
        if (!localStorage.getItem(CUSTOM_TRANSLATION_KEY) && Config.CUSTOM_TRANSLATION_FILE.length) {
            const resp = await fetch(Config.CUSTOM_TRANSLATION_FILE)
            const translation = await resp.json()

            if (Object.keys(translation).length !== 0) {
                localStorage.setItem(CUSTOM_TRANSLATION_KEY, JSON.stringify(translation))
                window.location.reload()
            }
        }
    } catch (e) {
        console.log("Error during fetch custom translation:", e)
    }

}

const startApp = async () => {
    await fetchCustomTranslations()
    ReactDOM.render(<App/>, document.getElementById('root'));
}

startApp()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
