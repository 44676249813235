import React, {Suspense} from 'react';
import {Route} from 'react-router-dom';

import Sidebar from '../components/Sidebar/Sidebar';
import {useTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";

type SuspenseBaseRouteProps = {
    path?: string | string[];
    component: React.LazyExoticComponent<React.ComponentType<RouteComponentProps<any>>>
    exact?: boolean;
    render?: any;
};

const PluginWrapper: React.FC<SuspenseBaseRouteProps> = (
    {
        component: Component,
        ...props
    }) => {

    const {t} = useTranslation()

    const loadingComponent = <div>{t('common:loading')}</div>

    return (
        <Route
            {...props}
            render={(renderProps) => (
                <>
                    <div style={{display: 'flex'}}>
                        <Sidebar/>
                        <Suspense fallback={loadingComponent}>
                            <Component {...renderProps} />
                        </Suspense>
                    </div>
                </>
            )}
        />
    );
};

export default PluginWrapper;
