import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        overflowY: 'hidden',
        backgroundColor: Colors.SmokeBackground,
        position: 'relative',
    },

    scrollable: {
        overflowY: 'auto',
        flex: 1,
        position: 'relative',
    },

    content :{
        marginBottom:40,
        paddingBottom:40
    }
}));
export const prepareCss = (id: string, styles: string) => {
    return styles
        .split('}')
        .map((rule) => (rule.trim() ? `#${id} ${rule}}` : ''))
        .join('');
};
export const isJQueryLoaded = () => {
    // @ts-ignore
    return typeof window.jQuery !== 'undefined';
};
export const isScriptLoaded = (src: string) => {
    return [...document.scripts].some((script) => script.src.includes(src));
};
export const removeScript = (src: string) => {
    document
        .querySelectorAll(`script[src="${src}"]`)
        .forEach((script) => script.remove());
};
export const initState = { htmlContent: '', cssContent: '', jsContent: '' };