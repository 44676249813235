import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import NotFound from '../../views/NotFound/NotFound';
import { PluginContentState, PluginErrors } from '../../store/types/Plugins';
import {
    initState,
    isJQueryLoaded,
    isScriptLoaded,
    prepareCss,
    removeScript,
    useStyles,
} from './utils';
import classNames from 'classnames';
import { api } from '../../store/services/axios';
import JSONFormData from '../../utils/JSONFormData';

const jQueryUrl = 'https://code.jquery.com/jquery-3.6.0.min.js';

// @ts-ignore
const PluginLoader = (props) => {
    const scriptUrl = `/plugins/${props.directory}/${props.jsFile}`;
    const cssUrl = `/plugins/${props.directory}/${props.cssFile}`;
    const htmlUrl = `/plugins/${props.directory}/${props.htmlFile}`;

    const classes = useStyles();
    const sessionData = useSelector((state: ReduxState) => state.auth);

    const [content, setContent] = useState<PluginContentState>({
        ...initState,
    });

    const [showErrorScreen, setShowErrorScreen] = useState(false);
    const pluginId = `custom-plugin-${props.component}`;

    useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();

        const fetchData = async () => {
            try {
                const [cssRes, htmlRes] = await Promise.all([
                    fetch(cssUrl, { signal: abortController.signal }),
                    fetch(htmlUrl, { signal: abortController.signal }),
                ]);

                const cssData = await cssRes.text();
                const htmlData = await htmlRes.text();

                if (isMounted) {
                    setContent((prevState) => ({
                        ...prevState,
                        cssContent: prepareCss(pluginId, cssData),
                        htmlContent: htmlData,
                    }));
                }
            } catch (error) {
                // @ts-ignore
                if (error.name !== PluginErrors.AbortError) {
                    setShowErrorScreen(true);
                    console.error(
                        `Error during render custom plugin id:${pluginId} errors:`,
                        error,
                    );
                }
            }
        };


        const loadScript = (src: string) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.async = true;

                script.onload = () => {
                    console.log(`Script ${src} loaded.`);
                    if (isMounted) {
                        // @ts-ignore
                        resolve();
                    }
                };

                script.onerror = () => {
                    console.error(`Error loading script: ${src}`);
                    reject();
                };

                document.body.appendChild(script);
            });
        };

        const handleScripts = async () => {
            if (!isJQueryLoaded()) {
                await loadScript(jQueryUrl);
            }
            if (!isScriptLoaded(scriptUrl)) {
                await loadScript(scriptUrl);
            }
        };

        if (!sessionData.session_id) {
            console.error('Auth data is missing!');
            setShowErrorScreen(true);
        } else {
            // @ts-ignore
            window.PB = {
                config: { ...props },
                sessionData: { ...sessionData },
                apiHandler: {
                    jsonRequest: async (endpoint: string, payload: any) => {
                        const { session_id, csrf_token } = sessionData;
                        // @ts-ignore
                        const body = new JSONFormData(session_id, csrf_token);
                        body.setParams({ ...payload });

                        return api.post(endpoint, body);
                    },
                },
            };

            fetchData();
            handleScripts();
        }

        return () => {
            isMounted = false;
            abortController.abort();
            removeScript(scriptUrl);
        };
    }, [sessionData]);

    if (showErrorScreen) {
        return <NotFound />;
    }

    return (
        <div
            style={{ padding: '48px 96px', paddingRight: '0px' }}
            className={classes.root}
        >
            <style dangerouslySetInnerHTML={{ __html: content.cssContent }} />
            <div
                id={`custom-plugin-${props.component}`}
                className={classNames(classes.scrollable, classes.content)}
                dangerouslySetInnerHTML={{ __html: content.htmlContent }}
            />
            <script dangerouslySetInnerHTML={{ __html: content.jsContent }} />
        </div>
    );
};

export default PluginLoader;
