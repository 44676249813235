import {call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import * as actions from '../../actions';
import {api} from '../../services/axios';
import {AxiosResponse} from 'axios';
import {ReduxState} from '../../types';
import JSONFormData from '../../../utils/JSONFormData';
import {ActionType} from 'typesafe-actions';
import {Account, CallControl, Customer, Generic} from '../../../services/endpoints';
import {UIConfigInfo} from '../../types/Generic';
import {
    BaseWallboardWidget,
    CallVolumesWidgetData,
    ExtensionPresenceWidgetData,
    RefreshWidgetQueueItem,
    RefreshWidgetTime,
    WidgetCallHistory,
    WidgetCallVolumesTimeRange,
    WidgetDataType,
    WidgetExtensionPresenseFilter,
    WidgetPresantationType
} from '../../types/Wallboard';
import dayjs from '../../../services/customDayJs';
import {fetchAccountList} from '../generic/saga';
import {AccountListResponse} from '../../types/Account';
import {RingGroupType} from '../../types/RingGroup';
import {GetSipCallsListResponse, SipCall, SipCallState} from '../../actions/ringgroups/payloads';
import {getDurationFromSec} from '../../../utils/transformers';
import {ExtensionType} from '../../types/Extension';
import {convertUserLocalTimeToUtc} from '../../../utils/dateWithTimezoneConversion';
import {pagedDataRequest} from '../paged.data.saga';
import {showToast} from "../../../utils/showToast";
import {
    CustomWidgetPeriods,
    GetTransactionsTotalInfoResponse,
    MultipleServiceInfo,
    ServiceInfo,
    WidgetItemCalls
} from "../../types/Billing";
import {getServiceColor} from "../../../styles/Colors";

const UIConfigItemKey = 'wallboards';
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export function* getWallboardData() {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            section_name: UIConfigItemKey
        });

        const res: AxiosResponse<{
            ui_config_list: UIConfigInfo[];
        }> = yield api.post(Generic.GetUIConfigList, body);

        const itm = res.data?.ui_config_list?.[0]?.value;

        if (itm) {
            const objects: BaseWallboardWidget[] = JSON.parse(itm);
            yield put(actions.getWallboardData.success(objects));
        } else {
            yield put(actions.getWallboardData.success([]));
        }

        yield put(actions.refreshWallboardTrigger.request({
            refreshImmediately: true,
            id: undefined
        }));

    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        yield put(actions.getWallboardData.failure(error));
    }
}

export function* callAllTransactionDataForWidget(action: ActionType<typeof actions.callAllTransactionDataForWidget.request>) {

    try {

        const {id, meta, init, extension, ringGroup} = action.payload;
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const {extensionsList} = yield select((state: ReduxState) => state.wallboard);

        const body = new JSONFormData(session_id, csrf_token);

        if (ringGroup?.length) {

            const params = {
                get_main_office_huntgroups: 1,
                group_number: ringGroup
            };

            const res: RingGroupType[] = yield pagedDataRequest<RingGroupType>(Customer.GetHuntGroupList, params, (data) => data.huntgroup_list)

            // @ts-ignore
            const data: ExtensionType[] = res.length ? res[0].assigned_extensions : []
            const output2 = []

            data.forEach(aExt => {
                const index = extensionsList.findIndex(ex => ex.extension_id === aExt.id)

                if (index !== -1) {
                    output2.push(extensionsList[index])
                }
            })


            // @ts-ignore
            const output: WidgetItemCalls [] = output2.map(el => ({
                id,
                extension: el,
                loading: true,
                details: {sum: 0, duration: 0},
                loaded: false
            }));

            const hasMore = false;

            const toReturn = {
                widgetId: id,
                init: true,
                hasMore,
                meta: {id, init, meta: hasMore ? {...meta, offset: meta.offset + meta.limit} : meta},
                items: output
            }

            yield put(actions.callAllTransactionDataForWidget.success(toReturn));

        } else if (extension?.length || extension) {

            const data: ExtensionType[] = []

            extension.forEach(aExt => {
                const index = extensionsList.findIndex(ex => ex.extension_id === aExt)

                if (index !== -1) {
                    data.push(extensionsList[index])
                }
            })

            const output: WidgetItemCalls [] = data.map(el => ({
                id,
                extension: el,
                loading: true,
                details: {sum: 0, duration: 0},
                loaded: false
            }));

            const hasMore = false;

            const toReturn = {
                widgetId: id,
                init: true,
                hasMore,
                meta: {id, init, meta: hasMore ? {...meta, offset: meta.offset + meta.limit} : meta},
                items: output
            }

            yield put(actions.callAllTransactionDataForWidget.success(toReturn));


        } else {

            const payload = {
                "has_extension": 1,
                "get_only_real_accounts": 1,
                "get_not_closed_accounts": 1,
                "limit": meta.limit,
                "offset": meta.offset,
                get_total: 1
            }

            body.setParams(payload);

            const resTotal: AxiosResponse<AccountListResponse> = yield api.post(Account.GetAccountList, body);

            const data = resTotal.data?.account_list || [];

            const output: WidgetItemCalls [] = data.map(el => ({
                id,
                extension: el,
                loading: true,
                details: {sum: 0, duration: 0},
                loaded: false
            }));

            const hasMore = (meta.offset + meta.limit) < resTotal.data.total;

            const toReturn = {
                widgetId: id,
                init,
                hasMore,
                meta: {id, init, meta: hasMore ? {...meta, offset: meta.offset + meta.limit} : meta},
                items: output
            }

            yield put(actions.callAllTransactionDataForWidget.success(toReturn));
        }
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        yield put(actions.callAllTransactionDataForWidget.failure(error));
    }
}

export function* getExtensionsListForWidgets(action: ActionType<typeof actions.getExtensionsListForWidgets.request>) {

    try {
        const resTotal: AxiosResponse<AccountListResponse> = yield fetchAccountList(undefined, {
                "has_extension": 1,
                "get_only_real_accounts": 1,
                "get_not_closed_accounts": 1,
                "limit": 100,
                "offset": 0,
                "useNewLogic": true
            },
            action.payload.skipService,
            action.payload.limitAliasDidNumberList,
        );

        const data = resTotal.data?.account_list || [];
        yield put(actions.getExtensionsListForWidgets.success(data));

        yield put(
            actions.validatesAndPostDataToQueue.request()
        );
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        yield put(actions.getExtensionsListForWidgets.failure(error));
    }
}

export function* updateWallboards(
    action: ActionType<typeof actions.updateWallboards.request>) {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            ui_config_list: [
                {
                    section_name: UIConfigItemKey,
                    value: JSON.stringify(action.payload.data)
                }
            ],
        });

        const res: AxiosResponse<{
            success: number;
        }> = yield api.post(Generic.UpdateUIConfigList, body);

        if (res.data.success) {
            action.payload.messageOnSuccess && showToast(action.payload.messageOnSuccess);
            action.payload.onSuccess?.();
            yield put(actions.updateWallboards.success(action.payload.data));
        } else {
            yield put(actions.updateWallboards.failure({
                faultcode: 'unknown error',
                faultstring: 'unknown error'
            }));
        }
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        yield put(actions.updateWallboards.failure(error));
    }
}

export function* getCallHistoryDataForWidget() {
    try {
        // @ts-ignore
        const timezoneOffset = yield select((state: ReduxState) => state.generic.sessionData?.tz_offset) || 0;

        const date = dayjs.utc();
        const fromDate = date.utcOffset(timezoneOffset / 60).add(-7, 'day').format(dateFormat);
        const toDate = date.utcOffset(timezoneOffset / 60).format(dateFormat);

        const params = {
            show_unsuccessful: '0',
            i_service_type: '3',
            from_date: convertUserLocalTimeToUtc(fromDate, timezoneOffset),
            to_date: convertUserLocalTimeToUtc(toDate, timezoneOffset),
        };

        const callHistoryItems: WidgetCallHistory[] = yield pagedDataRequest<WidgetCallHistory>(Customer.GetCustomerXDRS, params, (data) => data.xdr_list);

        const formatTime = dateFormat.split(' ')[1];
        for (const call of callHistoryItems) {
            const startTime = dayjs.utc(call.connect_time ?? call.bill_time, dateFormat);
            const finishTime = dayjs.utc(call.disconnect_time ?? toDate, dateFormat);

            const diffDuration = dayjs.utc(finishTime.diff(startTime));
            call.callDuration = diffDuration.format(formatTime);

            const diffInSec = date.diff(startTime) / 1000;
            call.startedMinutesAgo = diffInSec / 60;
            call.durationInSec = finishTime.diff(startTime) / 1000;
        }

        yield put(
            actions.getCallHistoryDataForWidget.success(callHistoryItems)
        );

        yield put(
            actions.validatesAndPostDataToQueue.request()
        );
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        yield put(actions.getCallHistoryDataForWidget.failure(error));
    }
}

export function* getRingGroupsListForWidgets() {
    try {
        const params = {
            get_main_office_huntgroups: 1
        };

        const data: RingGroupType[] = yield pagedDataRequest<RingGroupType>(Customer.GetHuntGroupList, params, (data) => data.huntgroup_list);

        yield put(actions.getRingGroupsListForWidgets.success(data));

        yield put(
            actions.validatesAndPostDataToQueue.request()
        );
    } catch (err) {
        //@ts-ignore
        yield put(actions.getRingGroupsListForWidgets.failure(err));
    }
}


function filterData(widgets: BaseWallboardWidget[], refreshWidgetsQueue: RefreshWidgetQueueItem[]) {
    return widgets.filter(el =>
        el.dataType === 'callvolumes' &&
        el.presentation == 'doughnut' &&
        refreshWidgetsQueue.find(qel => qel.id === el.id))

}

function buildArray(filtered: ServiceInfo[]) {
    const output: ServiceInfo[] = []
    filtered.forEach((item) => {
        if (output.find((el) => el.i_service === item.i_service)) {
            output.forEach((el, index) => {
                if (el.i_service == item.i_service) {
                    output[index] = {
                        ...el,
                        charged_amount: el.charged_amount + item.charged_amount,
                        charged_quantity: el.charged_quantity + item.charged_quantity,
                        total_sessions: el.total_sessions + item.total_sessions,
                    }
                }
            })
        } else {

            output.push({...item, color: getServiceColor(item.i_service)})
        }
    })

    return output;
}


function* dataForDonut(fromDate: string, toDate: string, ext: string) {

    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const {extensionsList} = yield select((state: ReduxState) => state.wallboard);
    const body = new JSONFormData(session_id, csrf_token);

    try {

        const index = extensionsList.findIndex(ex => ex.extension_id === ext)

        if (index !== -1) {

            body.setParams({
                i_account: extensionsList[index].i_account,
                from_date: fromDate,
                to_date: toDate,
            });

            const res2: AxiosResponse<GetTransactionsTotalInfoResponse> = yield api.post(
                Account.TransactionsTotalInfo,
                body,
            );


            return res2.data.per_service_info.filter(item => {
                return item.i_service_type === 3
            })
        } else {
            return []
        }


    } catch (e) {
        return []
    }


}

function* fetchDataForSelectedExtInWidget(extensions: string[], fromDate: string, toDate: string, timezoneOffset: number) {

    const output: ServiceInfo[] = [];

    for (let j = 0; j < extensions.length; j++) {

        // @ts-ignore
        const filtered = yield dataForDonut(convertUserLocalTimeToUtc(fromDate, timezoneOffset), convertUserLocalTimeToUtc(toDate, timezoneOffset), extensions[j])
        const newData = buildArray(filtered)

        if (newData.length) {

            newData.forEach(el => {

                const index = output.findIndex(el2 => el2.i_service === el.i_service)

                if (index !== -1) {

                    let temp = {...output[index]}

                    temp = {
                        ...temp,
                        total_sessions: temp.total_sessions + el.total_sessions,
                        total: temp.total + el.total,
                        charged_quantity: temp.charged_quantity + el.charged_quantity,
                    }

                    output[index] = temp


                } else {
                    output.push(el)
                }
            })

        }


    }

    return output
}

function filterWidgetsForFetchTransactionList(widgets: BaseWallboardWidget[], refreshWidgetsQueue: RefreshWidgetQueueItem[]) {
    return filterData(widgets, refreshWidgetsQueue).sort((el1, el2) => {
        if (el1.data.show === 'all' && el2.data.show !== 'all') {
            return 1;
        }
        if (el1.data.show !== 'all' && el2.data.show === 'all') {
            return -1;
        }
        return 0;
    });
}

function makeErrorPayload(e: any) {
    let foultcode: string
    let faultstring: string

    //@ts-ignore
    if (e.response?.data?.faultstring) {

        //@ts-ignore
        foultcode = e.response?.data?.foultcode
        //@ts-ignore
        faultstring = e.response?.data?.faultstring

    } else {
        foultcode = '500'
        //@ts-ignore
        faultstring = e.message
    }

    return {faultstring, foultcode}
}

export function* getTransactionsListForWidgets() {

    let currentWidgetId = ''
    try {

        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const {widgets} = yield select((state: ReduxState) => state.wallboard);
        const {refreshWidgetsQueue} = yield select((state: ReduxState) => state.wallboard);
        const {customWidgetPeriods} = yield select((state: ReduxState) => state.wallboard);
        const timezoneOffset: number = yield select((state: ReduxState) => state.generic.sessionData?.tz_offset) || 0;


        if (refreshWidgetsQueue.length) {

            const toFetch = filterWidgetsForFetchTransactionList(widgets, refreshWidgetsQueue)

            const refreshed = [];
            const failed = []
            if (toFetch.length) {
                for (let i = 0; i < toFetch.length; i++) {

                    try {

                        currentWidgetId = toFetch[i].id


                        const body2 = new JSONFormData(session_id, csrf_token);
                        const id = toFetch[i].id
                        const periodToUse = customWidgetPeriods.find((p: CustomWidgetPeriods) => p.id == id)?.periodRange || toFetch[i].data.timeRange

                        const date = dayjs.utc();

                        const fromDate = date.utcOffset(timezoneOffset / 60).add(-periodToUse, 'm').format(dateFormat);
                        const toDate = date.utcOffset(timezoneOffset / 60).format(dateFormat);


                        if (toFetch[i].data.show == 'selected_extensions') {

                            const extToFetch = [...toFetch[i].data.extensions]
                            const output: ServiceInfo[] = yield fetchDataForSelectedExtInWidget(extToFetch, fromDate, toDate, timezoneOffset)
                            refreshed.push({id: toFetch[i].id, items: output})


                        } else if (toFetch[i].data.show == 'selected_ring_groups') {
                            const params = {
                                get_main_office_huntgroups: 1,
                                group_number: toFetch[i].data.ringGroup
                            };
                            const res: RingGroupType[] = yield pagedDataRequest<RingGroupType>(Customer.GetHuntGroupList, params, (data) => data.huntgroup_list)

                            // @ts-ignore
                            const data: ExtensionType[] = res.length ? res[0].assigned_extensions.map(el => el.id) : []


                            const extToFetch = [...data]
                            const output: ServiceInfo[] = yield fetchDataForSelectedExtInWidget(extToFetch, fromDate, toDate, timezoneOffset)
                            refreshed.push({id: toFetch[i].id, items: output})


                        } else {

                            body2.setParams({
                                from_date: convertUserLocalTimeToUtc(fromDate, timezoneOffset),
                                to_date: convertUserLocalTimeToUtc(toDate, timezoneOffset),
                                for_id: currentWidgetId
                            });

                            const res2: AxiosResponse<GetTransactionsTotalInfoResponse> = yield api.post(
                                Customer.GetTransactionsTotalInfo,
                                body2,
                            );

                            const filtered = res2.data.per_service_info.filter(item => {
                                return item.i_service_type === 3
                            })
                            const output = buildArray(filtered);

                            refreshed.push({id: toFetch[i].id, items: output})

                        }

                    } catch (e: any) {

                        if (e.response?.data?.faultstring !== 'Access denied') {
                            throw new Error(e)
                        } else {

                            // @ts-ignore
                            failed.push({[currentWidgetId]: makeErrorPayload(e)})
                        }
                    }

                }

                yield put(actions.getTransactionsListForWidgets.success(refreshed));

                for (let j = 0; j < failed.length; j++) {
                    // @ts-ignore
                    yield put(actions.getTransactionsListForWidgets.failure(failed[j]));
                }

            }
        } else {
            yield put(actions.getTransactionsListForWidgets.success([]));
        }

        yield put(actions.validatesAndPostDataToQueue.request());
    } catch (err) {
        // @ts-ignore
        yield put(actions.getTransactionsListForWidgets.failure({[currentWidgetId]: makeErrorPayload(err)}));
    }
}

export function* getSipCallsListForWidgetsData() {
    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const resSipCalls: AxiosResponse<GetSipCallsListResponse> = yield api.post(
        CallControl.GetSipCallsList,
        body,
    );

    if (resSipCalls?.data?.calls_list?.length) {
        for (const call of resSipCalls.data.calls_list) {
            calculateSipCallDuration(call, resSipCalls.data.server_time);
        }
    }

    return resSipCalls.data.calls_list;
}

export function* getSipCallsListForWidgets() {
    try {
        const resultData: SipCall[] = yield getSipCallsListForWidgetsData();
        yield put(actions.getSipCallsListForWidgets.success(resultData));

        yield put(
            actions.validatesAndPostDataToQueue.request()
        );
    } catch (error) {
        //@ts-ignore
        const apiError = error?.response?.data;
        yield put(
            actions.getSipCallsListForWidgets.failure(apiError),
        );
    }
}

const actionsOfCallVolumes: Function[] = [
    getExtensionsListForWidgets,
    getRingGroupsListForWidgets,
];

const actionsOfTransactions: Function[] = [
    getTransactionsListForWidgets
];

const actionsOfDynamicList: Function[] = [
    fetchCallsDataForExtensionWidget
];

const actionsOfExtensionPresence: Function[] = [
    getSipCallsListForWidgets,
    getExtensionsListForWidgets,
    getRingGroupsListForWidgets,
];

export function* validatesAndPostDataToQueue() {

    try {
        const widgets: BaseWallboardWidget[] | undefined = yield select((state: ReduxState) => state.wallboard?.widgets);
        const callHistoryItems: WidgetCallHistory[] = yield select((state: ReduxState) => state.wallboard.callHistoryItems);
        const isLoadingHistoryCalls: boolean = yield select((state: ReduxState) => state.wallboard.historyCallsLoading);

        const sipCallsList: SipCall[] = yield select((state: ReduxState) => state.wallboard.sipCallsList);
        const isLoadingSipCalls: boolean = yield select((state: ReduxState) => state.wallboard.isLoadingSipCalls);

        const extensionsList: ExtensionType[] = yield select((state: ReduxState) => state.wallboard.extensionsList);
        const isLoadingExtensions: boolean = yield select((state: ReduxState) => state.wallboard.isLoadingExtensions);

        const ringGroupsList: RingGroupType[] = yield select((state: ReduxState) => state.wallboard.ringGroupsList);
        const transactionsList: MultipleServiceInfo[] = yield select((state: ReduxState) => state.wallboard.transactionList);
        const isLoadingRingGroups: boolean = yield select((state: ReduxState) => state.wallboard.isLoadingRingGroups);
        const isLoadingTransaction: boolean = yield select((state: ReduxState) => state.wallboard.isLoadingTransaction);

        const refreshWidgetsQueue: RefreshWidgetQueueItem[] = yield select((state: ReduxState) => state.wallboard?.refreshWidgetsQueue);

        let hasUpdates = false;
        for (const itm of refreshWidgetsQueue) {
            const w = widgets?.find(e => e.id === itm.id);
            if (!w) continue;
            if (w.dataType === WidgetDataType.externalIframe) continue;
            if (w.dataType === WidgetDataType.callVolumes) {
                if (isLoadingHistoryCalls) continue;
                if (isLoadingExtensions) continue;
                if (isLoadingRingGroups) continue;
                if (isLoadingTransaction) continue;

                itm.data = {
                    callHistoryItems: callHistoryItems,
                    extensionsList: extensionsList,
                    ringGroupsList: ringGroupsList,
                    transactionsList: transactionsList
                } as CallVolumesWidgetData;

                itm.dataHasLoaded = true;
                hasUpdates = true;
            } else if (w.dataType === WidgetDataType.extensionPresense) {
                if (isLoadingSipCalls) continue;
                if (isLoadingExtensions) continue;
                if (isLoadingRingGroups) continue;

                itm.data = {
                    sipCallsList: sipCallsList,
                    extensionsList: extensionsList,
                    ringGroupsList: ringGroupsList,
                } as ExtensionPresenceWidgetData;
                itm.dataHasLoaded = true;
                hasUpdates = true;
            }
        }

        if (hasUpdates) {
            yield put(
                actions.validatesAndPostDataToQueue.success(refreshWidgetsQueue),
            );
        }
    } catch (error) {
        yield put(
            actions.validatesAndPostDataToQueue.failure(),
        );
    }
}

export function* refreshWallboardTrigger(
    action: ActionType<typeof actions.refreshWallboardTrigger.request>) {

    try {
        const widgets: BaseWallboardWidget[] | undefined = yield select((state: ReduxState) => state.wallboard?.widgets);
        const refreshTime: string = yield select((state: ReduxState) => state.wallboard?.refreshTime);
        const refreshWidgetsTime: RefreshWidgetTime[] = yield select((state: ReduxState) => state.wallboard?.refreshWidgetsTime);
        const refreshWidgetsQueue: RefreshWidgetQueueItem[] = yield select((state: ReduxState) => state.wallboard?.refreshWidgetsQueue);

        let widgetsToCheckForUpdates = (action.payload.id
            ? widgets?.filter(e => e.id === action.payload.id)
            : widgets) || [];

        if (!action.payload.refreshImmediately) {
            widgetsToCheckForUpdates = widgetsToCheckForUpdates.filter(el => el.autoRefreshTime)
        }

        const nowTime = dayjs(new Date(), dateFormat);
        const nowTimeString = nowTime.format(dateFormat)
        const widgetsToBeUpdated: BaseWallboardWidget[] = [];
        for (const w of widgetsToCheckForUpdates) {
            const lastRefreshTimeObject = refreshWidgetsTime.find(e => e.id === w.id);
            const lastRefreshTime = lastRefreshTimeObject?.time || refreshTime;
            const diff = nowTime.diff(dayjs(lastRefreshTime, dateFormat), 'second');
            if (diff >= w.autoRefreshTime || action.payload.refreshImmediately) {
                const isAlreadyExists = !!refreshWidgetsQueue.find(e => e.id === w.id);
                if (!isAlreadyExists) {
                    widgetsToBeUpdated.push(w);
                }
            }
        }

        if (widgetsToBeUpdated.length) {
            const methodsToBeExecuted: Function[] = [];
            for (const wtu of widgetsToBeUpdated) {
                refreshWidgetsQueue.push({
                    id: wtu.id,
                    dataHasLoaded: false,
                    data: {},
                    initLoadStart: nowTimeString
                });
                if (wtu.dataType === WidgetDataType.callVolumes) {
                    for (const a of actionsOfCallVolumes) {
                        if (!methodsToBeExecuted.find(e => e === a)) {
                            methodsToBeExecuted.push(a);
                        }
                    }

                    if (wtu.presentation === WidgetPresantationType.doughnut) {
                        for (const a of actionsOfTransactions) {
                            if (!methodsToBeExecuted.find(e => e === a)) {
                                methodsToBeExecuted.push(a);
                            }
                        }
                    } else if (wtu.presentation === WidgetPresantationType.list) {
                        for (const a of actionsOfDynamicList) {
                            if (!methodsToBeExecuted.find(e => e === a)) {
                                methodsToBeExecuted.push(a);
                            }
                        }
                    }
                } else if (wtu.dataType === WidgetDataType.extensionPresense) {
                    for (const a of actionsOfExtensionPresence) {
                        if (!methodsToBeExecuted.find(e => e === a)) {
                            methodsToBeExecuted.push(a);
                        }
                    }
                }
            }

            if (methodsToBeExecuted.length) {
                for (const method of methodsToBeExecuted) {
                    if (method === getCallHistoryDataForWidget) {
                        yield put(actions.getCallHistoryDataForWidget.request());
                    } else if (method === getExtensionsListForWidgets) {
                        yield put(actions.getExtensionsListForWidgets.request({
                            skipService: true,
                            limitAliasDidNumberList: true
                        }));
                    } else if (method === getRingGroupsListForWidgets) {
                        yield put(actions.getRingGroupsListForWidgets.request());
                    } else if (method === getSipCallsListForWidgets) {
                        yield put(actions.getSipCallsListForWidgets.request());
                    } else if (method === getTransactionsListForWidgets) {
                        yield put(actions.getTransactionsListForWidgets.request());
                    } else if (method === fetchCallsDataForExtensionWidget) {
                        yield call(fetchCallsDataForExtensionWidget);
                    } else {
                        throw 'Not Implemented!';
                    }
                }
            }

            yield put(
                actions.refreshWallboardTrigger.success({
                    refreshWidgetsQueue: refreshWidgetsQueue,
                    refreshWidgetsTime: refreshWidgetsTime
                }),
            );
        }
    } catch (error) {

        yield put(
            actions.refreshWallboardTrigger.failure(),
        );
    }
}

export function* fetchCallsDataForExtensionWidget() {
    const customTransactionRefreshData: WidgetItemCalls[] = yield select((state: ReduxState) => state.wallboard?.customTransactionRefreshData) ?? [];

    for (const itm of customTransactionRefreshData) {
        const obj = {
            ...itm,
            loaded: false,
            period: null
        };
        //@ts-ignore
        yield put(actions.fetchCallDataPerExtensionWidgetItem.success(obj));
    }
}

export function* refreshWidgetCompleted(
    action: ActionType<typeof actions.refreshWidgetCompleted.request>) {

    const refreshWidgetsTime: RefreshWidgetTime[] = yield select((state: ReduxState) => state.wallboard?.refreshWidgetsTime);
    const refreshWidgetsQueue: RefreshWidgetQueueItem[] = yield select((state: ReduxState) => state.wallboard?.refreshWidgetsQueue);

    const nowTime = dayjs(new Date(), dateFormat);

    let rmIndx = refreshWidgetsTime.findIndex(e => e.id === action.payload);
    while (rmIndx !== -1) {
        refreshWidgetsTime.splice(rmIndx, 1);
        rmIndx = refreshWidgetsTime.findIndex(e => e.id === action.payload);
    }

    let indx = refreshWidgetsQueue.findIndex(e => e.id === action.payload);
    let lastRemoveObject: RefreshWidgetQueueItem | undefined = undefined;
    while (indx !== -1) {
        lastRemoveObject = refreshWidgetsQueue[indx];
        refreshWidgetsQueue.splice(indx, 1);
        indx = refreshWidgetsQueue.findIndex(e => e.id === action.payload);
    }

    refreshWidgetsTime.push({
        id: action.payload,
        time: lastRemoveObject?.initLoadStart ? lastRemoveObject.initLoadStart : nowTime.format(dateFormat)
    });

    yield put(actions.refreshWidgetCompleted.success({
        refreshWidgetsQueue: refreshWidgetsQueue,
        refreshWidgetsTime: refreshWidgetsTime
    }));
}

export function* fetchCallDataPerExtensionWidgetItem(
    action: ActionType<typeof actions.fetchCallDataPerExtensionWidgetItem.request>
) {

    const {id, period, i_account} = action.payload
    const timezoneOffset: number = yield select((state: ReduxState) => state.generic.sessionData?.tz_offset) || 0;
    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {

        const date = dayjs.utc();

        const fromDate = date.utcOffset(timezoneOffset / 60).add(-period, 'm').format(dateFormat);
        const toDate = date.utcOffset(timezoneOffset / 60).format(dateFormat);

        body.setParams({
            i_account,
            from_date: convertUserLocalTimeToUtc(fromDate, timezoneOffset),
            to_date: convertUserLocalTimeToUtc(toDate, timezoneOffset),
        });

        const res2: AxiosResponse<GetTransactionsTotalInfoResponse> = yield api.post(
            Account.TransactionsTotalInfo,
            body,
        );

        const filtered = res2.data.per_service_info.filter(item => {
            return item.i_service_type === 3
        })

        let sum = 0
        let duration = 0


        filtered.forEach(el => {
            sum += el.total_sessions
            duration += el.charged_quantity
        })

        action.payload?.callback && action.payload?.callback()

        yield put(actions.fetchCallDataPerExtensionWidgetItem.success({
            id,
            loading: false,
            details: {sum, duration},
            loaded: true,
            period: action.payload.period ?? WidgetCallVolumesTimeRange.min10080
        }));

    } catch (err) {
        // @ts-ignore
        yield put(actions.fetchCallDataPerExtensionWidgetItem.failure({id, error: err}));

    }
}

function calculateSipCallDuration(call: SipCall, serverTimeAsString: string | undefined) {
    const formatTime = dateFormat.split(' ')[1];
    if (call.state === SipCallState.Connected) {
        //For state connected, calculate the duration:
        //      server_time - connect_time and transform to HH:MM:SS format
        if (serverTimeAsString && call.connect_time) {
            const serverTime = dayjs(serverTimeAsString, dateFormat);
            const connectTime = dayjs(call.connect_time, dateFormat);
            const serverdiff = Math.abs(serverTime.diff(connectTime));
            const diff = dayjs.utc(serverdiff);
            call.duration = diff.format(formatTime);
            const diff2 = serverTime.diff(connectTime) / 1000;
            call.durationFormatSec = getDurationFromSec(diff2).string;
        }
    } else if (serverTimeAsString && call.update_time) {
        //For others states calculate the duration:
        //  server_time - update_time and transform to HH:MM:SS format
        const serverTime = dayjs(serverTimeAsString, dateFormat);
        const updateTime = dayjs(call.update_time, dateFormat);
        const serverdiff = Math.abs(serverTime.diff(updateTime));
        const diff = dayjs.utc(serverdiff);
        call.duration = diff.format(formatTime);
        const diff2 = serverTime.diff(updateTime) / 1000;
        call.durationFormatSec = getDurationFromSec(diff2).string;
    }
}

export function* updateSipCallStatusForWidgetsByWebSocketEvent(
    action: ActionType<typeof actions.updateSipCallStatusForWidgetsByWebSocketEvent.request>,
) {
    try {
        const call = action.payload;

        calculateSipCallDuration(call, undefined);
        const sipCallsList: SipCall[] = yield select((state: ReduxState) => state.wallboard.sipCallsList ?? []);
        if (call.previous_tracking_id) {
            const toRemoveCallIndex = sipCallsList.findIndex(e => e.tracking_id === call.tracking_id);
            if (toRemoveCallIndex !== -1) {
                sipCallsList.splice(toRemoveCallIndex, 1);
                call.tracking_id = call.previous_tracking_id;
                call.previous_tracking_id = undefined;
            }
        }

        const callIndex = sipCallsList.findIndex(e => e.tracking_id === call.tracking_id
            && e.call.id === call.call.id
            && e.call.tag === call.call.tag);

        if (call.state === SipCallState.Terminated) {
            if (callIndex !== -1) {
                sipCallsList.splice(callIndex, 1);
            }
        } else if (callIndex === -1) {
            sipCallsList.splice(0, 0, call);
        } else {
            sipCallsList[callIndex] = call;
        }

        yield put(actions.updateSipCallStatusForWidgetsByWebSocketEvent.success([...sipCallsList]));

        yield updateSipCallsStatusForAllTheWidgets(sipCallsList, call);
    } catch (error) {
        //@ts-ignore
        const apiError = error?.response?.data;
        yield put(
            actions.updateSipCallStatusForWidgetsByWebSocketEvent.failure(apiError),
        );
    }
}

function* updateSipCallsStatusForAllTheWidgets(sipCallsList: SipCall[], call: SipCall | undefined) {
    const widgets: BaseWallboardWidget[] | undefined = yield select((state: ReduxState) => state.wallboard?.widgets);
    const refreshWidgetsQueueStore: RefreshWidgetQueueItem[] | undefined = yield select((state: ReduxState) => state.wallboard?.refreshWidgetsQueue);
    const extensionsList: ExtensionType[] = yield select((state: ReduxState) => state.wallboard.extensionsList);
    const ringGroupsList: RingGroupType[] = yield select((state: ReduxState) => state.wallboard.ringGroupsList);

    let hasUpdates = false;
    const refreshWidgetsQueue: RefreshWidgetQueueItem[] = [];
    const nowTime = dayjs(new Date(), dateFormat);
    const nowTimeString = nowTime.format(dateFormat);
    for (const w of widgets ?? []) {
        if (!w) continue;
        if (w.dataType === WidgetDataType.extensionPresense) {
            const filteredExtensions = (() => {
                if (w.data.show === WidgetExtensionPresenseFilter.selected_extensions) {
                    return extensionsList?.filter(e => !!w.data.extensions?.find(k => k === e.extension_id)) ?? [];
                } else if (w.data.show === WidgetExtensionPresenseFilter.selected_ring_groups) {
                    const groups = ringGroupsList?.filter(e => w.data.ringGroup === e.id) ?? [];
                    const extIds: string[] = [];
                    for (const r of groups) {
                        for (const e of r.assigned_extensions ?? []) {
                            extIds.push(e.id || '');
                        }
                    }
                    return extensionsList?.filter(e => !!extIds?.find(k => k === e.extension_id)) ?? [];
                } else {
                    return extensionsList ?? [];
                }
            })();

            let isWidgetHasExtensionInList = false;
            if (!call) {
                isWidgetHasExtensionInList = true;
            } else if (call.callee?.extension_id && filteredExtensions.findIndex(c => c.extension_id === call.callee.extension_id) !== -1) {
                isWidgetHasExtensionInList = true;
            } else if ((call.caller?.extension_id && filteredExtensions.findIndex(c => c.extension_id === call.caller.extension_id) !== -1)) {
                isWidgetHasExtensionInList = true;
            }

            if (isWidgetHasExtensionInList) {
                const itm: RefreshWidgetQueueItem = {
                    id: w.id,
                    dataHasLoaded: true,
                    initLoadStart: nowTimeString,
                    data: {
                        sipCallsList: sipCallsList,
                        ringGroupsList: ringGroupsList,
                        extensionsList: filteredExtensions
                    } as ExtensionPresenceWidgetData
                };
                refreshWidgetsQueue.push(itm);
                hasUpdates = true;
            }
        } else {
            const exitInQueue = refreshWidgetsQueueStore?.find(el => el.id === w.id)
            exitInQueue && refreshWidgetsQueue.push(exitInQueue);
        }
    }

    if (hasUpdates) {
        yield put(
            actions.refreshWallboardTrigger.success({
                refreshWidgetsQueue: refreshWidgetsQueue,
            }),
        );
    }

    yield put(
        actions.validatesAndPostDataToQueue.request()
    );
}

export function* recalcSipCallsDurationForWidget() {
    try {
        const sipCallsList: SipCall[] = yield select((state: ReduxState) => state.wallboard.sipCallsList ?? []);
        for (const call of sipCallsList) {
            if (call.duration === undefined || call.duration === null) {
                call.duration = "00:00:00";
                call.durationFormatSec = undefined;
                continue;
            }
            const baseDate = '2024-01-01';
            const baseDateFull = '2024-01-01 00:00:00';
            const updatedDuration = dayjs(baseDate + 'T' + call.duration, dateFormat).add(1, 'seconds');
            const formatTime = dateFormat.split(' ')[1];
            call.duration = updatedDuration.format(formatTime);
            const djs = dayjs(baseDateFull, dateFormat);
            const diff2 = updatedDuration.diff(djs) / 1000;
            call.durationFormatSec = getDurationFromSec(diff2).string;
        }
        yield put(actions.recalcSipCallsDurationForWidget.success([...sipCallsList]));
        yield updateSipCallsStatusForAllTheWidgets(sipCallsList, undefined);
    } catch (error) {
        //@ts-ignore
        const apiError = error?.response?.data;
        yield put(
            actions.recalcSipCallsDurationForWidget.failure(apiError),
        );
    }
}

export const wallboardSaga = [
    takeLatest(actions.getWallboardData.request, getWallboardData),
    takeLatest(actions.getTransactionsListForWidgets.request, getTransactionsListForWidgets),
    takeLatest(actions.updateWallboards.request, updateWallboards),
    takeLatest(actions.refreshWallboardTrigger.request, refreshWallboardTrigger),
    takeLatest(actions.refreshWidgetCompleted.request, refreshWidgetCompleted),
    takeLatest(actions.getCallHistoryDataForWidget.request, getCallHistoryDataForWidget),
    takeLatest(actions.getExtensionsListForWidgets.request, getExtensionsListForWidgets),
    takeLatest(actions.getRingGroupsListForWidgets.request, getRingGroupsListForWidgets),
    takeLatest(actions.getSipCallsListForWidgets.request, getSipCallsListForWidgets),
    takeLatest(actions.validatesAndPostDataToQueue.request, validatesAndPostDataToQueue),
    takeEvery(actions.fetchCallDataPerExtensionWidgetItem.request, fetchCallDataPerExtensionWidgetItem),
    takeEvery(actions.callAllTransactionDataForWidget.request, callAllTransactionDataForWidget),
    takeLatest(actions.updateSipCallStatusForWidgetsByWebSocketEvent.request, updateSipCallStatusForWidgetsByWebSocketEvent),
    takeEvery(actions.recalcSipCallsDurationForWidget.request, recalcSipCallsDurationForWidget),
];
