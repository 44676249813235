import React from "react";

export interface PluginConfig {
    route: string;
    routerLink: string;
    section?: string;
    linkLabel: string;
    restricted: boolean;
    component: string;
    sectionLogo?:string
    directory:string
    data: Record<string, string>;
};


export interface ComponentPlugin extends PluginConfig {
    Component: React.LazyExoticComponent<React.ComponentType<any>>;

}

export interface PluginContentState {
    htmlContent: string;
    jsContent: string;
    cssContent: string;
}

export enum PluginErrors {
    AbortError = 'AbortError'
}